import React from 'react';
import ThoiKhoaBieu from './ThoiKhoaBieu';
import './assets/style.css'; // Import CSS nội bộ cho component App

function App() {
  return (
    <div>
      <ThoiKhoaBieu />
    </div>
  );
}

export default App;
