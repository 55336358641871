import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';

// Hàm để lấy giá trị từ cookie
function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
}

// Hàm để lưu giá trị vào cookie
function setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function ThoiKhoaBieu() {
    const [lop, setLop] = useState(getCookie('lop') || 'CCNTT 22C');
    const [tuan, setTuan] = useState(getCookie('tuan') || '3');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadThoiKhoaBieu = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post('/backend/api.php', {
                lop: lop,
                tuan: tuan
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            setData(response.data);
        } catch (error) {
            setError('Không thể tải dữ liệu');
            console.error("Không thể tải dữ liệu", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadThoiKhoaBieu();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setCookie('lop', lop, 365);
        setCookie('tuan', tuan, 365);
        loadThoiKhoaBieu();
    };

    // Hiệu ứng cho các phần tử
    const fadeInUp = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0px)' },
        config: { duration: 600 },
    });

    const fadeInDown = useSpring({
        from: { opacity: 0, transform: 'translateY(-20px)' },
        to: { opacity: 1, transform: 'translateY(0px)' },
        config: { duration: 600 },
    });

    return (
        <div className="container mt-5">
            <animated.div style={fadeInUp} className="col-12 mb-4">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="tuan" className="fw-bold">Tuần</label>
                                    <input
                                        type="text"
                                        id="tuan"
                                        name="tuan"
                                        className="form-control"
                                        placeholder="Nhập tuần học"
                                        value={tuan}
                                        onChange={(e) => setTuan(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="lop" className="fw-bold">Lớp</label>
                                    <input
                                        type="text"
                                        id="lop"
                                        name="lop"
                                        className="form-control"
                                        placeholder="Nhập mã lớp"
                                        value={lop}
                                        onChange={(e) => setLop(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 text-center">
                                    <button type="submit" className="btn btn-warning">
                                        <i className="fas fa-search me-2"></i>Xem thời khóa biểu
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </animated.div>

            <animated.div style={fadeInDown} className="col-12 mb-4">
                <div className="card">
                    <div className="card-header text-center">
                        <h3 className="text-light m-0">THỜI KHÓA BIỂU TRƯỜNG CTEC</h3>
                    </div>
                    <div className="card-body">
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="table-responsive">
                            <animated.table style={fadeInUp} id="thoikhoabieu" className="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>NGÀY</th>
                                        <th>THỨ</th>
                                        <th>BUỔI</th>
                                        <th>MÃ MÔN</th>
                                        <th>TÊN MÔN</th>
                                        <th>PHÒNG THỰC HÀNH</th>
                                        <th>GIẢNG VIÊN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        data.length > 0 ? (
                                            data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.STT}</td>
                                                    <td>{item.Ngay}</td>
                                                    <td>{item.Thu}</td>
                                                    <td>{item.Buoi}</td>
                                                    <td>{item.MaMon}</td>
                                                    <td>{item.TenMon}</td>
                                                    <td>{item.PhongThucHanh}</td>
                                                    <td>{item.GiangVien}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">Không có dữ liệu</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </animated.table>
                        </div>
                    </div>
                </div>
            </animated.div>
        </div>
    );
}

export default ThoiKhoaBieu;
